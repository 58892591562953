.grid-showcase {
  > * {
    outline: 1px solid var(--bs-blue);
  }
  &__inner {
    min-height: 85vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    outline: 1px solid var(--bs-red);
  }

  &--overlay {
    @include abs(0);

    .row {
      align-items: flex-start;

      > * {
        $num-colors: 100;
        @for $i from 0 to $num-colors {
          &:nth-child(#{$i}) {
            --bg: #{rgba(nth(map-values($colors), random(length(map-values($colors)))), 0.5)};
          }
        }
      }

      .demo-block {
        min-height: 100px;
        display: block;
        background-color: var(--bg, gray);
        box-shadow: #0000ff63 inset 0px 0px 0px 10px;
      }
    }
  }
}

.headings {
  display: flex;

  & > * {
    width: 50%;
  }

  .jumbo-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
  }
}

.button-styles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > li {
    width: calc((100% / 3) - 20px);
    margin-bottom: 50px;
    border-bottom: 1px solid var(--bs-gray-500);
    padding-bottom: 50px;
    margin-left: -10px;

    @include media-breakpoint-down(md) {
      width: calc((100% / 2) - 20px);
    }

    @include media-breakpoint-down(sm) {
      width: calc((100%) - 20px);
    }
  }
}
