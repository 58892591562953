// Boostrap
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-color: var(--bs-gray-300);
$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;

// Other
//? Put own extra variables here