fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;

  .icon {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}
