.text-max-width {
  max-width: map-get($text-max-widths, sm);

  @each $size, $width in $text-max-widths {
    &-#{$size} {
      max-width: $width;
    }
  }

  @each $bp-name, $bp-width in $grid-breakpoints {
    @each $size, $width in $text-max-widths {
      @media screen and (min-width: #{$bp-width}) {
        &-#{$bp-name}-#{$size} {
          max-width: $width !important;
        }
      }
    }
  }
}
