// Imports
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

// Bootstrap
$font-size-base: 16;

$font-family-sans-serif: 'PT Sans', sans-serif;
$font-family-serif: 'Merriweather', serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 48px,
  2: 39px,
  3: 31px,
  4: 25px,
  5: 20px,
  6: 16px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 20px,
  body-lg: 26px,
);

$headings-font-family: $font-family-serif;
$headings-font-weight: 700;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-lg);
$lead-font-weight: 600;

$text-muted: var(--bs-gray-600);

// Other
//? Put own extra variables here
