@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap");
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(0, 123, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(40, 167, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 123, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #000 !important;
  background-color: RGBA(173, 181, 189, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #fd7e14 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #fd9843 !important;
}

.link-secondary {
  color: #007bff !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #3395ff !important;
}

.link-success {
  color: #28a745 !important;
}
.link-success:hover, .link-success:focus {
  color: #53b96a !important;
}

.link-info {
  color: #007bff !important;
}
.link-info:hover, .link-info:focus {
  color: #3395ff !important;
}

.link-warning {
  color: #fd7e14 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #fd9843 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: white !important;
}
.link-light:hover, .link-light:focus {
  color: white !important;
}

.link-dark {
  color: black !important;
}
.link-dark:hover, .link-dark:focus {
  color: black !important;
}

.link-muted {
  color: #adb5bd !important;
}
.link-muted:hover, .link-muted:focus {
  color: #bdc4ca !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 390px) {
  .sticky-xsm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xsm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-2 {
  font-size: calc(1.36875rem + 1.425vw) !important;
}

.fs-3 {
  font-size: calc(1.31875rem + 0.825vw) !important;
}

.fs-4 {
  font-size: calc(1.28125rem + 0.375vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-body-xs {
  font-size: 0.875rem !important;
}

.fs-body-sm {
  font-size: 1rem !important;
}

.fs-body-md {
  font-size: 1.25rem !important;
}

.fs-body-lg {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-gray-600) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 390px) {
  .float-xsm-start {
    float: left !important;
  }
  .float-xsm-end {
    float: right !important;
  }
  .float-xsm-none {
    float: none !important;
  }
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 1.5rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 1.5rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
  .gap-xsm-0 {
    gap: 0 !important;
  }
  .gap-xsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xsm-3 {
    gap: 1rem !important;
  }
  .gap-xsm-4 {
    gap: 1.5rem !important;
  }
  .gap-xsm-5 {
    gap: 3rem !important;
  }
  .text-xsm-start {
    text-align: left !important;
  }
  .text-xsm-end {
    text-align: right !important;
  }
  .text-xsm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3rem !important;
  }
  .fs-2 {
    font-size: 2.4375rem !important;
  }
  .fs-3 {
    font-size: 1.9375rem !important;
  }
  .fs-4 {
    font-size: 1.5625rem !important;
  }
  .fs-body-lg {
    font-size: 1.625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0.75rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container {
    max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xsm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 390px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

li {
  list-style-type: none;
}

blockquote,
q {
  quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 16;
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
}

h6,
.h6, h5,
.h5,
.app .fstr-cookie-notice .fstr-cookie-notice__title, h4,
.h4, h3,
.h3, h2,
.h2, h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Merriweather", serif;
  font-weight: 700;
  line-height: 1.2;
  word-break: break-word;
}

h1,
.h1 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: calc(1.36875rem + 1.425vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.4375rem;
  }
}

h3,
.h3 {
  font-size: calc(1.31875rem + 0.825vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.9375rem;
  }
}

h4,
.h4 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5625rem;
  }
}

h5,
.h5,
.app .fstr-cookie-notice .fstr-cookie-notice__title {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.625rem;
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .lead {
    font-size: 1.25rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

.type-body-xs {
  font-size: 0.875rem;
}

.type-body-sm {
  font-size: 1rem;
}

.type-body-md {
  font-size: 1.25rem;
}

.type-body-lg {
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  .type-body-lg {
    font-size: 1.625rem;
  }
}

strong {
  font-weight: bold;
}

body {
  --bs-primary: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-primary-h: 27.2961373391deg;
  --bs-primary-s: 98.3122362869%;
  --bs-primary-l: 53.5294117647%;
  --bs-secondary: hsl(211.0588235294deg, 100%, 50%);
  --bs-secondary-h: 211.0588235294deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 50%;
  --bs-success: hsl(133.7007874016deg, 61.3526570048%, 40.5882352941%);
  --bs-success-h: 133.7007874016deg;
  --bs-success-s: 61.3526570048%;
  --bs-success-l: 40.5882352941%;
  --bs-info: hsl(211.0588235294deg, 100%, 50%);
  --bs-info-h: 211.0588235294deg;
  --bs-info-s: 100%;
  --bs-info-l: 50%;
  --bs-warning: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-warning-h: 27.2961373391deg;
  --bs-warning-s: 98.3122362869%;
  --bs-warning-l: 53.5294117647%;
  --bs-danger: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-danger-h: 354.251497006deg;
  --bs-danger-s: 70.4641350211%;
  --bs-danger-l: 53.5294117647%;
  --bs-light: hsl(0deg, 0%, 100%);
  --bs-light-h: 0deg;
  --bs-light-s: 0%;
  --bs-light-l: 100%;
  --bs-dark: hsl(0deg, 0%, 0%);
  --bs-dark-h: 0deg;
  --bs-dark-s: 0%;
  --bs-dark-l: 0%;
  --bs-muted: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-muted-h: 210deg;
  --bs-muted-s: 10.8108108108%;
  --bs-muted-l: 70.9803921569%;
  --bs-blue: hsl(211.0588235294deg, 100%, 50%);
  --bs-blue-h: 211.0588235294deg;
  --bs-blue-s: 100%;
  --bs-blue-l: 50%;
  --bs-indigo: hsl(262.8318584071deg, 89.6825396825%, 50.5882352941%);
  --bs-indigo-h: 262.8318584071deg;
  --bs-indigo-s: 89.6825396825%;
  --bs-indigo-l: 50.5882352941%;
  --bs-purple: hsl(261.2598425197deg, 50.5976095618%, 50.7843137255%);
  --bs-purple-h: 261.2598425197deg;
  --bs-purple-s: 50.5976095618%;
  --bs-purple-l: 50.7843137255%;
  --bs-pink: hsl(332.4705882353deg, 78.7037037037%, 57.6470588235%);
  --bs-pink-h: 332.4705882353deg;
  --bs-pink-s: 78.7037037037%;
  --bs-pink-l: 57.6470588235%;
  --bs-red: hsl(354.251497006deg, 70.4641350211%, 53.5294117647%);
  --bs-red-h: 354.251497006deg;
  --bs-red-s: 70.4641350211%;
  --bs-red-l: 53.5294117647%;
  --bs-orange: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-orange-h: 27.2961373391deg;
  --bs-orange-s: 98.3122362869%;
  --bs-orange-l: 53.5294117647%;
  --bs-yellow: hsl(45deg, 100%, 51.3725490196%);
  --bs-yellow-h: 45deg;
  --bs-yellow-s: 100%;
  --bs-yellow-l: 51.3725490196%;
  --bs-green: hsl(133.7007874016deg, 61.3526570048%, 40.5882352941%);
  --bs-green-h: 133.7007874016deg;
  --bs-green-s: 61.3526570048%;
  --bs-green-l: 40.5882352941%;
  --bs-teal: hsl(162.2485207101deg, 72.5321888412%, 45.6862745098%);
  --bs-teal-h: 162.2485207101deg;
  --bs-teal-s: 72.5321888412%;
  --bs-teal-l: 45.6862745098%;
  --bs-cyan: hsl(188.198757764deg, 77.7777777778%, 40.5882352941%);
  --bs-cyan-h: 188.198757764deg;
  --bs-cyan-s: 77.7777777778%;
  --bs-cyan-l: 40.5882352941%;
  --bs-gray-900: hsl(210deg, 10.8108108108%, 14.5098039216%);
  --bs-gray-900-h: 210deg;
  --bs-gray-900-s: 10.8108108108%;
  --bs-gray-900-l: 14.5098039216%;
  --bs-gray-800: hsl(210deg, 10.3448275862%, 22.7450980392%);
  --bs-gray-800-h: 210deg;
  --bs-gray-800-s: 10.3448275862%;
  --bs-gray-800-l: 22.7450980392%;
  --bs-gray-700: hsl(210deg, 8.75%, 31.3725490196%);
  --bs-gray-700-h: 210deg;
  --bs-gray-700-s: 8.75%;
  --bs-gray-700-l: 31.3725490196%;
  --bs-gray-600: hsl(208.2352941176deg, 7.2961373391%, 45.6862745098%);
  --bs-gray-600-h: 208.2352941176deg;
  --bs-gray-600-s: 7.2961373391%;
  --bs-gray-600-l: 45.6862745098%;
  --bs-gray-500: hsl(210deg, 10.8108108108%, 70.9803921569%);
  --bs-gray-500-h: 210deg;
  --bs-gray-500-s: 10.8108108108%;
  --bs-gray-500-l: 70.9803921569%;
  --bs-gray-400: hsl(210deg, 13.9534883721%, 83.137254902%);
  --bs-gray-400-h: 210deg;
  --bs-gray-400-s: 13.9534883721%;
  --bs-gray-400-l: 83.137254902%;
  --bs-gray-300: hsl(210deg, 13.7931034483%, 88.6274509804%);
  --bs-gray-300-h: 210deg;
  --bs-gray-300-s: 13.7931034483%;
  --bs-gray-300-l: 88.6274509804%;
  --bs-gray-200: hsl(210deg, 15.7894736842%, 92.5490196078%);
  --bs-gray-200-h: 210deg;
  --bs-gray-200-s: 15.7894736842%;
  --bs-gray-200-l: 92.5490196078%;
  --bs-gray-100: hsl(210deg, 16.6666666667%, 97.6470588235%);
  --bs-gray-100-h: 210deg;
  --bs-gray-100-s: 16.6666666667%;
  --bs-gray-100-l: 97.6470588235%;
  --bs-font-sans-serif: "PT Sans", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-gutter-x: 1.5rem;
}

img {
  width: 100%;
  height: auto;
}

.theme-contrast {
  --bs-primary: hsl(27.2961373391deg, 98.3122362869%, 33.5294117647%);
  --bs-primary-h: 27.2961373391deg;
  --bs-primary-s: 98.3122362869%;
  --bs-primary-l: 33.5294117647%;
  --bs-secondary: hsl(211.0588235294deg, 100%, 30%);
  --bs-secondary-h: 211.0588235294deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 30%;
  --bs-success: hsl(133.7007874016deg, 61.3526570048%, 20.5882352941%);
  --bs-success-h: 133.7007874016deg;
  --bs-success-s: 61.3526570048%;
  --bs-success-l: 20.5882352941%;
  --bs-info: hsl(211.0588235294deg, 100%, 30%);
  --bs-info-h: 211.0588235294deg;
  --bs-info-s: 100%;
  --bs-info-l: 30%;
  --bs-warning: hsl(27.2961373391deg, 98.3122362869%, 33.5294117647%);
  --bs-warning-h: 27.2961373391deg;
  --bs-warning-s: 98.3122362869%;
  --bs-warning-l: 33.5294117647%;
  --bs-danger: hsl(354.251497006deg, 70.4641350211%, 33.5294117647%);
  --bs-danger-h: 354.251497006deg;
  --bs-danger-s: 70.4641350211%;
  --bs-danger-l: 33.5294117647%;
  --bs-dark: hsl(0deg, 0%, -20%);
  --bs-dark-h: 0deg;
  --bs-dark-s: 0%;
  --bs-dark-l: -20%;
  --bs-muted: hsl(210deg, 10.8108108108%, 50.9803921569%);
  --bs-muted-h: 210deg;
  --bs-muted-s: 10.8108108108%;
  --bs-muted-l: 50.9803921569%;
  --bs-blue: hsl(211.0588235294deg, 100%, 30%);
  --bs-blue-h: 211.0588235294deg;
  --bs-blue-s: 100%;
  --bs-blue-l: 30%;
  --bs-indigo: hsl(262.8318584071deg, 89.6825396825%, 30.5882352941%);
  --bs-indigo-h: 262.8318584071deg;
  --bs-indigo-s: 89.6825396825%;
  --bs-indigo-l: 30.5882352941%;
  --bs-purple: hsl(261.2598425197deg, 50.5976095618%, 30.7843137255%);
  --bs-purple-h: 261.2598425197deg;
  --bs-purple-s: 50.5976095618%;
  --bs-purple-l: 30.7843137255%;
  --bs-pink: hsl(332.4705882353deg, 78.7037037037%, 37.6470588235%);
  --bs-pink-h: 332.4705882353deg;
  --bs-pink-s: 78.7037037037%;
  --bs-pink-l: 37.6470588235%;
  --bs-red: hsl(354.251497006deg, 70.4641350211%, 33.5294117647%);
  --bs-red-h: 354.251497006deg;
  --bs-red-s: 70.4641350211%;
  --bs-red-l: 33.5294117647%;
  --bs-orange: hsl(27.2961373391deg, 98.3122362869%, 33.5294117647%);
  --bs-orange-h: 27.2961373391deg;
  --bs-orange-s: 98.3122362869%;
  --bs-orange-l: 33.5294117647%;
  --bs-yellow: hsl(45deg, 100%, 31.3725490196%);
  --bs-yellow-h: 45deg;
  --bs-yellow-s: 100%;
  --bs-yellow-l: 31.3725490196%;
  --bs-green: hsl(133.7007874016deg, 61.3526570048%, 20.5882352941%);
  --bs-green-h: 133.7007874016deg;
  --bs-green-s: 61.3526570048%;
  --bs-green-l: 20.5882352941%;
  --bs-teal: hsl(162.2485207101deg, 72.5321888412%, 25.6862745098%);
  --bs-teal-h: 162.2485207101deg;
  --bs-teal-s: 72.5321888412%;
  --bs-teal-l: 25.6862745098%;
  --bs-cyan: hsl(188.198757764deg, 77.7777777778%, 20.5882352941%);
  --bs-cyan-h: 188.198757764deg;
  --bs-cyan-s: 77.7777777778%;
  --bs-cyan-l: 20.5882352941%;
}

.palette-light {
  --bs-primary: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-primary-h: 27.2961373391deg;
  --bs-primary-s: 98.3122362869%;
  --bs-primary-l: 53.5294117647%;
  --bs-secondary: hsl(211.0588235294deg, 100%, 50%);
  --bs-secondary-h: 211.0588235294deg;
  --bs-secondary-s: 100%;
  --bs-secondary-l: 50%;
  --bs-primary-overlay: hsl(211.0588235294deg, 100%, 50%);
  --bs-primary-overlay-h: 211.0588235294deg;
  --bs-primary-overlay-s: 100%;
  --bs-primary-overlay-l: 50%;
  --bs-secondary-overlay: hsl(210deg, 16.6666666667%, 97.6470588235%);
  --bs-secondary-overlay-h: 210deg;
  --bs-secondary-overlay-s: 16.6666666667%;
  --bs-secondary-overlay-l: 97.6470588235%;
  --bs-tertiary: hsl(45deg, 100%, 51.3725490196%);
  --bs-tertiary-h: 45deg;
  --bs-tertiary-s: 100%;
  --bs-tertiary-l: 51.3725490196%;
}

.palette-dark {
  --bs-primary: hsl(211.0588235294deg, 100%, 50%);
  --bs-primary-h: 211.0588235294deg;
  --bs-primary-s: 100%;
  --bs-primary-l: 50%;
  --bs-secondary: hsl(27.2961373391deg, 98.3122362869%, 53.5294117647%);
  --bs-secondary-h: 27.2961373391deg;
  --bs-secondary-s: 98.3122362869%;
  --bs-secondary-l: 53.5294117647%;
  --bs-primary-overlay: hsl(0deg, 0%, 100%);
  --bs-primary-overlay-h: 0deg;
  --bs-primary-overlay-s: 0%;
  --bs-primary-overlay-l: 100%;
  --bs-secondary-overlay: hsl(45deg, 100%, 51.3725490196%);
  --bs-secondary-overlay-h: 45deg;
  --bs-secondary-overlay-s: 100%;
  --bs-secondary-overlay-l: 51.3725490196%;
  --bs-tertiary: hsl(162.2485207101deg, 72.5321888412%, 45.6862745098%);
  --bs-tertiary-h: 162.2485207101deg;
  --bs-tertiary-s: 72.5321888412%;
  --bs-tertiary-l: 45.6862745098%;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}

.bg-success {
  background-color: var(--bs-success) !important;
}

.bg-info {
  background-color: var(--bs-info) !important;
}

.bg-warning {
  background-color: var(--bs-warning) !important;
}

.bg-danger {
  background-color: var(--bs-danger) !important;
}

.bg-light {
  background-color: var(--bs-light) !important;
}

.bg-dark {
  background-color: var(--bs-dark) !important;
}

.bg-muted {
  background-color: var(--bs-muted) !important;
}

.bg-color-blue {
  background-color: var(--bs-blue) !important;
}

.bg-color-indigo {
  background-color: var(--bs-indigo) !important;
}

.bg-color-purple {
  background-color: var(--bs-purple) !important;
}

.bg-color-pink {
  background-color: var(--bs-pink) !important;
}

.bg-color-red {
  background-color: var(--bs-red) !important;
}

.bg-color-orange {
  background-color: var(--bs-orange) !important;
}

.bg-color-yellow {
  background-color: var(--bs-yellow) !important;
}

.bg-color-green {
  background-color: var(--bs-green) !important;
}

.bg-color-teal {
  background-color: var(--bs-teal) !important;
}

.bg-color-cyan {
  background-color: var(--bs-cyan) !important;
}

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important;
}

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important;
}

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important;
}

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important;
}

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important;
}

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important;
}

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important;
}

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important;
}

.bg-gray-100 {
  background-color: var(--bs-gray-100) !important;
}

/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */
[class^=section-] {
  position: relative;
}

[class^=section-negative-] {
  z-index: 1;
}

.section-inset-y {
  padding-top: 10vw;
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-y {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.section-inset-t {
  padding-top: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-t {
    padding-top: 6.25rem;
  }
}

.section-inset-b {
  padding-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-inset-b {
    padding-bottom: 6.25rem;
  }
}

.section-outset-y {
  margin-top: 10vw;
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-y {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}

.section-outset-t {
  margin-top: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-t {
    margin-top: 6.25rem;
  }
}

.section-outset-b {
  margin-bottom: 10vw;
}
@media (min-width: 1000px) {
  .section-outset-b {
    margin-bottom: 6.25rem;
  }
}

.section-negative-y {
  margin-top: -10vw;
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-y {
    margin-top: -6.25rem;
    margin-bottom: -6.25rem;
  }
}

.section-negative-t {
  margin-top: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-t {
    margin-top: -6.25rem;
  }
}

.section-negative-b {
  margin-bottom: -10vw;
}
@media (min-width: 1000px) {
  .section-negative-b {
    margin-bottom: -6.25rem;
  }
}

.text-max-width {
  max-width: 720px;
}
.text-max-width-xs {
  max-width: 550px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none:hover, .text-decoration-none:focus {
  text-decoration: none;
}

@media screen and (min-width: 800px) {
  .container-size-xs {
    max-width: 635px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-sm {
    max-width: 960px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-md {
    max-width: 1200px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xl {
    max-width: 1400px;
  }
}
@media screen and (min-width: 800px) {
  .container-size-xxl {
    max-width: 1600px;
  }
}

.container {
  --container-max-width: 100%;
}
@media (max-width: 767.98px) {
  .container {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media (min-width: 576px) {
  .container, .container-xsm, .container-sm {
    --container-max-width: 800px;
  }
}
@media (min-width: 768px) {
  .container, .container-xsm, .container-sm, .container-md {
    --container-max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg {
    --container-max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl {
    --container-max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    --container-max-width: 1320px;
  }
}
.text-max-width {
  max-width: 720px;
}
.text-max-width-xxs {
  max-width: 480px;
}
.text-max-width-xs {
  max-width: 550px;
}
.text-max-width-sm {
  max-width: 720px;
}
.text-max-width-md {
  max-width: 900px;
}
.text-max-width-lg {
  max-width: 1000px;
}
.text-max-width-xl {
  max-width: 1140px;
}
.text-max-width-xxl {
  max-width: 1320px;
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 0) {
  .text-max-width-xs-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 390px) {
  .text-max-width-xsm-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 576px) {
  .text-max-width-sm-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 768px) {
  .text-max-width-md-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 992px) {
  .text-max-width-lg-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 1200px) {
  .text-max-width-xl-xxl {
    max-width: 1320px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxs {
    max-width: 480px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xs {
    max-width: 550px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-sm {
    max-width: 720px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-md {
    max-width: 900px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-lg {
    max-width: 1000px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xl {
    max-width: 1140px !important;
  }
}
@media screen and (min-width: 1400px) {
  .text-max-width-xxl-xxl {
    max-width: 1320px !important;
  }
}

[data-object-position=topleft],
.object-position--topleft {
  object-position: top left;
}

[data-mask-position=topleft],
.mask-position--topleft {
  mask-position: top left;
}

[data-object-position=topcenter],
.object-position--topcenter {
  object-position: top center;
}

[data-mask-position=topcenter],
.mask-position--topcenter {
  mask-position: top center;
}

[data-object-position=topright],
.object-position--topright {
  object-position: top right;
}

[data-mask-position=topright],
.mask-position--topright {
  mask-position: top right;
}

[data-object-position=middleleft],
.object-position--middleleft {
  object-position: center left;
}

[data-mask-position=middleleft],
.mask-position--middleleft {
  mask-position: center left;
}

[data-object-position=middlecenter],
.object-position--middlecenter {
  object-position: center center;
}

[data-mask-position=middlecenter],
.mask-position--middlecenter {
  mask-position: center center;
}

[data-object-position=middleright],
.object-position--middleright {
  object-position: center right;
}

[data-mask-position=middleright],
.mask-position--middleright {
  mask-position: center right;
}

[data-object-position=bottomleft],
.object-position--bottomleft {
  object-position: bottom left;
}

[data-mask-position=bottomleft],
.mask-position--bottomleft {
  mask-position: bottom left;
}

[data-object-position=bottomcenter],
.object-position--bottomcenter {
  object-position: bottom center;
}

[data-mask-position=bottomcenter],
.mask-position--bottomcenter {
  mask-position: bottom center;
}

[data-object-position=bottomright],
.object-position--bottomright {
  object-position: bottom right;
}

[data-mask-position=bottomright],
.mask-position--bottomright {
  mask-position: bottom right;
}

.inset-s-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-1 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-2 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-3 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-4 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-5 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-6 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-7 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-8 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-9 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-10 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-11 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-s-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-e-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}
@media screen and (max-width: 960px) {
  .inset-e-12 {
    --real-container-width: calc(100vw - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  }
}

.inset-s-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}

.inset-e-xs-1 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
}

.inset-s-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}

.inset-e-xs-2 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
}

.inset-s-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}

.inset-e-xs-3 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
}

.inset-s-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}

.inset-e-xs-4 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
}

.inset-s-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}

.inset-e-xs-5 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
}

.inset-s-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}

.inset-e-xs-6 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
}

.inset-s-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}

.inset-e-xs-7 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
}

.inset-s-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}

.inset-e-xs-8 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
}

.inset-s-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}

.inset-e-xs-9 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
}

.inset-s-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}

.inset-e-xs-10 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
}

.inset-s-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}

.inset-e-xs-11 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
}

.inset-s-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}

.inset-e-xs-12 {
  --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
  --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
  margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
}

@media (min-width: 390px) {
  .inset-s-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xsm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xsm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xsm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xsm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xsm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xsm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xsm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xsm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xsm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xsm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xsm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 390px) {
  .inset-s-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xsm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-sm-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-sm-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-sm-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-sm-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-sm-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-sm-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-sm-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-sm-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-sm-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-sm-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-sm-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 576px) {
  .inset-s-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-sm-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-md-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-md-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-md-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-md-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-md-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-md-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-md-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-md-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-md-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-md-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-md-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 768px) {
  .inset-s-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-md-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-lg-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-lg-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-lg-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-lg-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-lg-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-lg-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-lg-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-lg-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-lg-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-lg-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-lg-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 992px) {
  .inset-s-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-lg-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 1200px) {
  .inset-s-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
  .inset-e-xxl-1 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 1) + (var(--bs-gutter-x) * 1)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
  .inset-e-xxl-2 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 2) + (var(--bs-gutter-x) * 2)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
  .inset-e-xxl-3 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 3) + (var(--bs-gutter-x) * 3)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
  .inset-e-xxl-4 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 4) + (var(--bs-gutter-x) * 4)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
  .inset-e-xxl-5 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 5) + (var(--bs-gutter-x) * 5)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
  .inset-e-xxl-6 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 6) + (var(--bs-gutter-x) * 6)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
  .inset-e-xxl-7 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 7) + (var(--bs-gutter-x) * 7)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
  .inset-e-xxl-8 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 8) + (var(--bs-gutter-x) * 8)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
  .inset-e-xxl-9 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 9) + (var(--bs-gutter-x) * 9)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
  .inset-e-xxl-10 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 10) + (var(--bs-gutter-x) * 10)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
  .inset-e-xxl-11 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 11) + (var(--bs-gutter-x) * 11)) * -1);
  }
}
@media (min-width: 1400px) {
  .inset-s-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-left: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
  .inset-e-xxl-12 {
    --real-container-width: calc(var(--container-max-width) - (var(--bs-gutter-x) * 2));
    --one-col: calc((var(--real-container-width) - (var(--bs-gutter-x) * 11)) / 12);
    margin-right: calc(((var(--one-col) * 12) + (var(--bs-gutter-x) * 12)) * -1);
  }
}
.pull-s,
.pull-e {
  --space-left: -0.75rem;
}
@media (min-width: 992px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}
@media (min-width: 1200px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}
@media (min-width: 1400px) {
  .pull-s,
  .pull-e {
    --space-left: calc(((100vw - var(--container-max-width) + (0.75rem)) / 2) * -1);
  }
}

.pull-s {
  margin-left: var(--space-left);
}

.pull-e {
  margin-right: var(--space-left);
}

@media (max-width: 767.98px) {
  .row {
    --bs-gutter-x: 0.8rem;
  }
}

.custom-scrollbar::-webkit-scrollbar-track, .markdown-content table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar, .markdown-content table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .markdown-content table::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.button, button,
input[type=button] {
  overflow: visible;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: 0;
  outline: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none; /* for input */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* for button */
}
.button:focus, button:focus,
input[type=button]:focus, .button:hover, button:hover,
input[type=button]:hover {
  outline: none;
  text-decoration: none;
}

.button {
  --button-background: var(--bs-muted);
  --button-background-darker: hsl(var(--bs-muted-h), var(--bs-muted-s), calc(var(--bs-muted-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-muted-h), var(--bs-muted-s), var(--bs-muted-l), 0.2);
  --button-color: white;
  --button-size: 2.5rem;
  --button-padding-x: 1.375rem;
  --button-border-width: 1px;
  --button-border-radius: 0.3125rem;
  display: inline-flex;
  position: relative;
  height: var(--button-size);
  align-items: center;
  padding-right: var(--button-padding-x);
  padding-left: var(--button-padding-x);
  padding-bottom: 0.1em;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: var(--button-background);
  color: var(--button-color);
  fill: currentColor;
  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;
  cursor: pointer;
  font-size: 0.875rem;
  justify-content: center;
}
@media (min-width: 768px) {
  .button {
    --button-size: 3rem;
    --button-padding-x: 1.65rem;
  }
}
@media (hover: hover) {
  .button:hover {
    background-color: var(--button-background-darker);
    color: var(--button-color);
  }
}
.button:disabled, .button[disabled=true] {
  pointer-events: none;
  opacity: 0.4;
}

.button {
  --icon-inset-factor: -0.5;
}
.button span + fstr-icon,
.button span + .css-icon {
  margin-inline-start: 0.5rem;
}
.button .icon-chip + span,
.button fstr-icon + span,
.button .css-icon + span {
  margin-inline-start: 0.5rem;
}
.button fstr-icon .icon {
  fill: currentColor;
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) fstr-icon:not(span + fstr-icon),
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) .css-icon:not(span + .css-icon) {
  margin-inline-start: calc(22px * var(--icon-inset-factor));
}
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + fstr-icon,
.button:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) span + .css-icon {
  margin-inline-end: calc(22px * var(--icon-inset-factor));
}
.button .icon-chip {
  --chip-spacing: 6px;
  --icon-inset-factor: -1;
  width: calc(var(--button-size) - var(--chip-spacing) * 2);
  height: calc(var(--button-size) - var(--chip-spacing) * 2);
  background: white;
  border-radius: 50%;
  fill: var(--button-background);
  color: var(--button-background);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));
}
.button .icon-chip svg {
  width: 18px;
  height: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.button span + .icon-chip {
  margin-inline-end: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));
}
.button.is--inactive {
  opacity: 0.5;
}

.button-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12.5rem;
  display: inline-flex;
  justify-content: center;
  padding: 0;
}
.button-icon fstr-icon {
  margin-inline-start: 0;
}
.button-icon span + fstr-icon {
  margin-inline-end: 0;
}

.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.button.button-link fstr-icon {
  min-width: 1.5em;
  min-height: 1.5em;
}
.button.button-link fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-link:hover {
    background: transparent;
    color: var(--button-background-darker);
  }
}

.button.button-outline {
  background: transparent;
  color: var(--button-background);
  border-color: var(--button-background);
}
.button.button-outline fstr-icon .icon {
  fill: currentColor;
}
@media (hover: hover) {
  .button.button-outline:hover {
    background: var(--button-background-alpha);
    color: var(--button-background-darker);
    border-color: var(--button-background-darker);
  }
}

.button--lg {
  --button-size: 3.875rem;
  --button-padding-x: 1.9375rem;
  font-size: calc(1.2875rem + 0.45vw);
}
@media (min-width: 1200px) {
  .button--lg {
    font-size: 1.625rem;
  }
}
.button--lg.button-icon {
  width: 3.875rem;
  height: 3.875rem;
  min-width: 3.875rem;
}

.button--md {
  --button-size: 3.125rem;
  --button-padding-x: 1.5625rem;
  font-size: 1.25rem;
}
.button--md.button-icon {
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
}

.button--sm, .app .fstr-cookie-notice .button {
  --button-size: 2.5rem;
  --button-padding-x: 1.25rem;
  font-size: 1rem;
}
.button--sm.button-icon, .app .fstr-cookie-notice .button-icon.button {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
}

.button--xs {
  --button-size: 2rem;
  --button-padding-x: 1rem;
  font-size: 0.875rem;
}
.button--xs.button-icon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}

.button.button--primary {
  --button-background: var(--bs-primary);
  --button-background-darker: hsl(var(--bs-primary-h), var(--bs-primary-s), calc(var(--bs-primary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-primary-h), var(--bs-primary-s), var(--bs-primary-l), 0.2);
  --button-color: white;
}

.button.button--secondary {
  --button-background: var(--bs-secondary);
  --button-background-darker: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-secondary-h), var(--bs-secondary-s), var(--bs-secondary-l), 0.2);
  --button-color: white;
}

.button.button--light {
  --button-background: var(--bs-light);
  --button-background-darker: hsl(var(--bs-light-h), var(--bs-light-s), calc(var(--bs-light-l) * 0.8));
  --button-background-alpha: hsla(var(--bs-light-h), var(--bs-light-s), var(--bs-light-l), 0.2);
  --button-color: var(--bs-dark);
}

fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;
}
fstr-icon .icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: inherit;
}

.icon-lg {
  width: 32px;
  height: 32px;
}
.icon-lg svg {
  width: 2rem;
  height: 2rem;
}

.icon-md {
  width: 28px;
  height: 28px;
}
.icon-md svg {
  width: 1.75rem;
  height: 1.75rem;
}

.icon-sm {
  width: 22px;
  height: 22px;
}
.icon-sm svg {
  width: 1.375rem;
  height: 1.375rem;
}

.icon-xs {
  width: 16px;
  height: 16px;
}
.icon-xs svg {
  width: 1rem;
  height: 1rem;
}

.markdown-content blockquote {
  position: relative;
  padding: 0 0 1.25em;
  margin: 4ex 0 4ex 0;
  font-family: "Merriweather", serif;
  text-align: center;
  display: flex;
  flex-flow: column;
}
.markdown-content blockquote::before {
  content: "“";
  font-size: 3em;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: var(--bs-primary);
  height: 1.5ex;
  line-height: 1;
  display: inline-block;
}
.markdown-content blockquote * {
  font-size: 1.2em;
}
.markdown-content blockquote *:last-child {
  margin-bottom: 0;
}
.markdown-content .fr-img-space-wrap {
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
}
.markdown-content .fr-inner {
  color: var(--bs-gray-600);
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}
.markdown-content img {
  max-width: 100%;
}
.markdown-content table {
  margin-top: 1.25rem;
  margin-bottom: 1em;
  font-size: 0.875rem;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: block;
}
@media (max-width: 767.98px) {
  .markdown-content table {
    width: calc(100% + 1.5rem);
    margin-right: 1.5rem;
  }
}
.markdown-content table th {
  background: var(--bs-primary);
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 0.7rem 0.5rem;
}
.markdown-content table tr td:not(:last-child) {
  border-right: 1px solid var(--bs-gray-200);
}
.markdown-content table tr th:not(:last-child) {
  border-right: 1px solid var(--bs-gray-200);
}
.markdown-content table tr td {
  padding: 0.5rem;
  min-width: 180px;
}
.markdown-content table tr td.fr-thick {
  font-weight: bold;
}
.markdown-content table tr td.fr-highlighted {
  border: 2px solid var(--bs-secondary);
}
.markdown-content table tr:nth-child(even) td {
  background: var(--bs-gray-100);
}
.markdown-content em,
.markdown-content i {
  font-style: italic;
}
.markdown-content strong {
  font-weight: 800;
}
.markdown-content h1,
.markdown-content .h1 {
  font-size: 2.625rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h2,
.markdown-content .h2 {
  font-size: 2.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h3,
.markdown-content .h3 {
  font-size: 1.75rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h4,
.markdown-content .h4 {
  font-size: 1.375rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h5,
.markdown-content .h5,
.markdown-content .app .fstr-cookie-notice .fstr-cookie-notice__title,
.app .fstr-cookie-notice .markdown-content .fstr-cookie-notice__title {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content h6,
.markdown-content .h6 {
  font-size: 1.125rem;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-weight: 500;
  color: var(--bs-primary);
}
.markdown-content ul,
.markdown-content ol {
  list-style: outside;
  padding-inline-start: 1.5rem;
  display: inline-block;
  margin-bottom: 1.25rem;
  width: 100%;
}
.markdown-content ul li,
.markdown-content ol li {
  display: list-item;
  list-style-type: initial;
  font-weight: 400;
}
.markdown-content ol li {
  list-style-type: decimal;
}
.markdown-content hr {
  margin: 1.5em 0;
}
.markdown-content p {
  margin-bottom: 3ex;
}
.markdown-content pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}
.markdown-content .button + .button {
  margin-inline-start: 0.5em;
}
.markdown-content a:not(.button) {
  color: var(--bs-secondary);
}
@media (hover: hover) {
  .markdown-content a:not(.button):hover {
    color: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
  }
}
.markdown-content .fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;
}
.markdown-content .fr-video iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.markdown-content iframe,
.markdown-content object,
.markdown-content embed {
  width: 100%;
  height: auto;
}

.simple-markdown p:last-child {
  margin-bottom: 0;
}

.embed-removed {
  background-color: var(--bs-background);
  text-align: center;
  padding: 2em 1em;
  margin: 2em 0;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embed-removed .fstr-cookie-btn.button {
  background: var(--bs-secondary);
}
.embed-removed .fstr-cookie-btn.button:hover {
  background: hsl(var(--bs-secondary-h), var(--bs-secondary-s), calc(var(--bs-secondary-l) * 0.8));
}
.embed-removed .embed-removed__text {
  font-weight: 600;
  color: var(--bs-background-text-color);
  margin-bottom: 1em;
}

.app .fstr-cookie-notice {
  --cookie-notice-bg: white;
  border-radius: 20px !important;
  max-width: 600px;
  border-width: 2px;
  border-color: var(--bs-secondary);
  border-radius: 0.25rem;
  padding: 2rem;
}
.app .fstr-cookie-notice .button-group {
  margin-top: 10px;
}
.app .fstr-cookie-notice .button-group .button--reject {
  display: none;
}
.app .fstr-cookie-notice .fstr-cookie-notice__title {
  margin-bottom: 2ex;
  color: var(--bs-secondary);
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content,
.app .fstr-cookie-notice .fstr-cookie-notice__content {
  font-size: 0.85em;
}
.app .fstr-cookie-notice .fstr-cookie-notice__toggle-row__content.open {
  padding: 1ex 0;
}
.app .fstr-cookie-notice .toggle-button {
  margin-left: 1ex;
  width: auto;
  height: auto;
  background-color: transparent;
  fill: #fd7e14;
  color: #fd7e14;
  padding: 0;
}
.app .fstr-cookie-notice .toggle-button span:first-of-type {
  display: none;
}
.app .fstr-cookie-notice .toggle-button .chevron {
  margin-left: 0;
}
.app .fstr-cookie-notice .button-group {
  display: flex;
  flex-wrap: wrap;
}
.app .fstr-cookie-notice .button {
  margin-bottom: 0 !important;
  text-align: center;
  justify-content: center;
}
.app .fstr-cookie-notice .button--settings {
  border: 2px solid;
  color: var(--bs-secondary);
  background-color: transparent;
}
.app .fstr-cookie-notice .button--primary {
  background-color: var(--bs-secondary);
}
.app .fstr-cookie-notice .button--primary:hover {
  background-color: var(--bs-secondary);
}
.app .fstr-cookie-notice .button--close {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 40px;
  height: 40px;
  font-size: 0px;
  background-color: transparent;
  padding: 0;
  color: var(--bs-secondary);
}
.app .fstr-cookie-notice .button--close::after, .app .fstr-cookie-notice .button--close::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background-color: currentColor;
}
.app .fstr-cookie-notice .button--close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.app .fstr-cookie-notice .button--close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-secondary-100);
  margin-bottom: 3ex;
}
.embed-removed__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  text-align: center;
}
@media (max-width: 575.98px) {
  .embed-removed__inner {
    height: auto;
  }
}
.embed-removed .fstr-cookie-btn.button {
  font-size: 0.9em;
  margin-top: 3ex;
  background-color: var(--bs-primary);
}

.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
}

.button-style {
  padding: 10px 10px 20px;
  min-height: 120px;
}
.button-style .text-muted {
  font-size: 12px;
  margin-bottom: 6px;
  display: inline-block;
}

.color-list {
  display: flex;
  flex-wrap: wrap;
}
.color-list__item {
  width: 10%;
  padding: 0 3px;
  text-align: center;
  margin-bottom: 2.5rem;
}

.styleguide-color {
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% - 8px);
  border: 4px solid var(--bs-gray-100);
  border-radius: 12.5rem;
}
.styleguide-color--active {
  border-color: var(--bs-gray-900);
}

.grid-showcase > * {
  outline: 1px solid var(--bs-blue);
}
.grid-showcase__inner {
  min-height: 85vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  outline: 1px solid var(--bs-red);
}
.grid-showcase--overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.grid-showcase--overlay .row {
  align-items: flex-start;
}
.grid-showcase--overlay .row > *:nth-child(0) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(1) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(2) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(3) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(4) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(5) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(6) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(7) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(8) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(9) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(10) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(11) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(12) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(13) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(14) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(15) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(16) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(17) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(18) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(19) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(20) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(21) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(22) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(23) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(24) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(25) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(26) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(27) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(28) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(29) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(30) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(31) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(32) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(33) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(34) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(35) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(36) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(37) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(38) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(39) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(40) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(41) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(42) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(43) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(44) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(45) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(46) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(47) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(48) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(49) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(50) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(51) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(52) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(53) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(54) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(55) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(56) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(57) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(58) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(59) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(60) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(61) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(62) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(63) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(64) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(65) {
  --bg: rgba(255, 193, 7, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(66) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(67) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(68) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(69) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(70) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(71) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(72) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(73) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(74) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(75) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(76) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(77) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(78) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(79) {
  --bg: rgba(253, 126, 20, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(80) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(81) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(82) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(83) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(84) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(85) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(86) {
  --bg: rgba(32, 201, 151, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(87) {
  --bg: rgba(0, 123, 255, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(88) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(89) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(90) {
  --bg: rgba(232, 62, 140, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(91) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(92) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(93) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(94) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(95) {
  --bg: rgba(23, 162, 184, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(96) {
  --bg: rgba(40, 167, 69, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(97) {
  --bg: rgba(102, 16, 242, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(98) {
  --bg: rgba(111, 66, 193, 0.5);
}
.grid-showcase--overlay .row > *:nth-child(99) {
  --bg: rgba(220, 53, 69, 0.5);
}
.grid-showcase--overlay .row .demo-block {
  min-height: 100px;
  display: block;
  background-color: var(--bg, gray);
  box-shadow: rgba(0, 0, 255, 0.3882352941) inset 0px 0px 0px 10px;
}

.headings {
  display: flex;
}
.headings > * {
  width: 50%;
}
.headings .jumbo-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
}
.icon-list__item {
  width: 33.3333333333%;
  display: flex;
  align-items: center;
}

.button-styles {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.button-styles > li {
  width: calc(33.3333333333% - 20px);
  margin-bottom: 50px;
  border-bottom: 1px solid var(--bs-gray-500);
  padding-bottom: 50px;
  margin-left: -10px;
}
@media (max-width: 767.98px) {
  .button-styles > li {
    width: calc(50% - 20px);
  }
}
@media (max-width: 575.98px) {
  .button-styles > li {
    width: calc(100% - 20px);
  }
}

.component-guide code {
  font-family: monospace;
  display: inline-block;
  padding: 5px 5px;
  background: var(--bs-gray-300);
  font-size: 1rem;
}

.footer {
  background: var(--bs-primary);
  text-align: center;
}

.footer__nav-list {
  justify-content: center;
  align-items: center;
}

.grid-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
.grid-overlay .grid-showcase__inner {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgba(255, 0, 0, 0.1490196078);
  outline: 1px solid rgba(255, 0, 0, 0.2901960784);
}

.breakpoint-util-list {
  position: fixed;
  top: 0;
  right: 0;
}
.breakpoint-util-list__item {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
  background: var(--bs-primary);
  color: white;
  padding: 10px 30px;
}

@keyframes loader {
  0% {
    transform: translateX(0%) scaleX(0);
  }
  50% {
    transform: translateX(33%) scaleX(33%);
  }
  100% {
    transform: translateX(100%) scaleX(0%);
  }
}
.loading {
  position: fixed;
  top: 0;
  height: 5px;
  background: #dddddd;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
}
.loading__progression {
  background: var(--bs-primary);
}
.loading__progression.type--flux {
  width: 100vw;
  animation: loader 2s infinite ease;
  transform-origin: left;
}