.button {
  --button-background: var(--bs-muted);
  --button-background-darker: #{darkenColor(var(--bs-muted), 0.2)};
  --button-background-alpha: #{alpha(var(--bs-muted), 0.2)};
  --button-color: white;
  --button-size: #{rem($button-size)};
  --button-padding-x: #{rem($button-padding-x)};
  --button-border-width: 1px;
  --button-border-radius: #{rem(5px)};

  @extend %button-reset;

  display: inline-flex;
  position: relative;
  height: var(--button-size);
  align-items: center;
  padding-right: var(--button-padding-x);
  padding-left: var(--button-padding-x);
  padding-bottom: 0.1em;
  font-family: $font-family-sans-serif;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: var(--button-background);
  color: var(--button-color);
  fill: currentColor;
  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid transparent;
  cursor: pointer;
  font-size: rem(14px);
  justify-content: center;

  @include media-breakpoint-up(md) {
    --button-size: #{rem($button-size * 1.2)};
    --button-padding-x: #{rem($button-padding-x * 1.2)};
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--button-background-darker);
      color: var(--button-color);
    }
  }

  &:disabled,
  &[disabled='true'] {
    pointer-events: none;
    opacity: 0.4;
  }
}

// Icons in buttons
.button {
  --icon-inset-factor: -0.5;

  span + fstr-icon,
  span + .css-icon {
    margin-inline-start: rem(8px);
  }

  .icon-chip + span,
  fstr-icon + span,
  .css-icon + span {
    margin-inline-start: rem(8px);
  }

  fstr-icon .icon {
    fill: currentColor;
  }

  &:not(.button-icon):not(.button-link):not(.button--xs):not(.button--sm) {
    fstr-icon:not(span + fstr-icon),
    .css-icon:not(span + .css-icon) {
      margin-inline-start: calc($button-padding-x * var(--icon-inset-factor));
    }

    span + fstr-icon,
    span + .css-icon {
      margin-inline-end: calc($button-padding-x * var(--icon-inset-factor));
    }
  }

  .icon-chip {
    --chip-spacing: 6px;
    --icon-inset-factor: -1;

    @include size(calc(var(--button-size) - (var(--chip-spacing) * 2)));
    background: white;
    border-radius: 50%;
    fill: var(--button-background);
    color: var(--button-background);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));

    svg {
      width: 18px;
      height: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  span + .icon-chip {
    margin-inline-end: calc((var(--button-padding-x) + var(--button-border-width)) * -1 + var(--chip-spacing));
  }

  &.is--inactive {
    opacity: 0.5;
  }
}
